import Vue from 'vue'
import Router from 'vue-router'
import Index from './views/Index/Index.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  activeClass: 'router-link-active',
  routes: [
    {
      path: '/',
      name: 'Index',
      mate: { keepAlive: false },
      component: Index,
    },
    {
      path: '/preClassSchedule',
      name: 'userClassSchedule',
      meta: { requireAuth: true, suitable: true },
      component: () => import ('@/views/User/ClassSchedule/ClassSchedule.vue'),
    },
    //精品文章
    {
      path: '/article/:id',
      name: 'articleDetail',

      component: () => import ('@/views/Article/Article.vue'),
    },
    {
      path: '/ielts',
      name: 'ielts',
      component: () => import ('@/modules/CategoryWrap/CategoryWrap.vue'),
      redirect: '/ielts/index',
      children: [
        {
          path: 'index',
          name: 'ieltsIndex',
          components: {
            default: import ('@/modules/CategoryIndex/CategoryIndex.vue'),
            ielts: () => import ('@/modules/CategoryIndex/CategoryIndex.vue'),
          },
        },
        {
          path: 'series',
          // props: { ielts: true },
          name: 'ieltsSeries',
          components: {
            default: import ('@/modules/CategorySeries/CategorySeries.vue'),
            ielts: () => import ('@/modules/CategorySeries/CategorySeries.vue'),
          },
        },
        {
          path: 'community',
          name: 'ieltsCommunity',
          components: {
            default: import ('@/views/Ielts/Ielts.vue'),
            ielts: () => import ('@/modules/CategoryCommunity/CategoryCommunity.vue'),
          },
        },
        {
          path: 'activity',
          components: {
            default: import ('@/views/Ielts/Ielts.vue'),
            ielts: () => import ('@/modules/CategoryActivity/CategoryActivity.vue'),
          },
        },
        {
          path: 'shop',
          components: {
            default: import ('@/views/Ielts/Ielts.vue'),
            ielts: () => import ('@/modules/CategoryShop/CategoryShop.vue'),
          },
        },
        {
          path: 'spoken',
          components: {
            default: import ('@/views/Ielts/Ielts.vue'),
            ielts: () => import ('@/views/Ielts/Spoken/Spoken.vue'),
          },
        },
        {
          path: 'material',
          components: {
            default: import ('@/views/Ielts/Ielts.vue'),
            ielts: () => import ('@/modules/CategoryMaterial/CategoryMaterial.vue'),
          },
        },
        {
          path: 'prediction',
          meta: { noFooter: true },
          components: {
            default: import ('@/views/Ielts/Ielts.vue'),
            ielts: () => import ('@/modules/CategoryPrediction/CategoryPrediction.vue'),
          },
        },
        {
          path: 'article',
          name: 'ieltsArticle',
          components: {
            default: import ('@/views/Ielts/Ielts.vue'),
            ielts: () => import ('@/modules/CategoryArticles/CategoryArticles.vue'),
          },
        },
      ],
    },


    {
      path: '/toefl',
      name: 'toefl',
      component: () => import ('@/modules/CategoryWrap/CategoryWrap.vue'),
      redirect: '/toefl/index',
      children: [
        {
          path: 'index',
          components: {
            default: import ('@/views/Toefl/Toefl.vue'),
            toefl: () => import ('@/modules/CategoryIndex/CategoryIndex.vue'),
          },
        },
        {
          path: 'series',
          // props: { toefl: true },
          name: 'toeflSeries',
          components: {
            default: import ('@/modules/CategorySeries/CategorySeries.vue'),
            toefl: () => import ('@/modules/CategorySeries/CategorySeries.vue'),
          },
        },
        {
          path: 'community',
          name: 'toeflCommunity',
          components: {
            default: import ('@/views/Toefl/Toefl.vue'),
            toefl: () => import ('@/modules/CategoryCommunity/CategoryCommunity.vue'),
          },
        },
        {
          path: 'activity',
          components: {
            default: import ('@/views/Toefl/Toefl.vue'),
            toefl: () => import ('@/modules/CategoryActivity/CategoryActivity.vue'),
          },
        },
        {
          path: 'shop',
          components: {
            default: import ('@/views/Toefl/Toefl.vue'),
            toefl: () => import ('@/modules/CategoryShop/CategoryShop.vue'),
          },
        },
        {
          path: 'material',
          components: {
            default: import ('@/views/Toefl/Toefl.vue'),
            toefl: () => import ('@/modules/CategoryMaterial/CategoryMaterial.vue'),
          },
        },
        {
          path: 'article',
          name: 'toeflArticle',
          components: {
            default: import ('@/views/Toefl/Toefl.vue'),
            toefl: () => import ('@/modules/CategoryArticles/CategoryArticles.vue'),
          },
        },
      ],
    },
    {
      path: '/gre',
      name: 'gre',
      component: () => import ('@/modules/CategoryWrap/CategoryWrap.vue'),
      redirect: '/gre/index',
      children: [
        {
          path: 'index',
          components: {
            default: import ('@/views/Gre/Gre.vue'),
            gre: () => import ('@/modules/CategoryIndex/CategoryIndex.vue'),
          },
        },
        {
          path: 'series',
          // props: { gre: true },
          name: 'greSeries',
          components: {
            default: import ('@/modules/CategorySeries/CategorySeries.vue'),
            gre: () => import ('@/modules/CategorySeries/CategorySeries.vue'),
          },
        },
        {
          path: 'community',
          name: 'greCommunity',
          components: {
            default: import ('@/views/Gre/Gre.vue'),
            gre: () => import ('@/modules/CategoryCommunity/CategoryCommunity.vue'),
          },
        },
        {
          path: 'activity',
          components: {
            default: import ('@/views/Gre/Gre.vue'),
            gre: () => import ('@/modules/CategoryActivity/CategoryActivity.vue'),
          },
        },
        {
          path: 'shop',
          components: {
            default: import ('@/views/Gre/Gre.vue'),
            gre: () => import ('@/modules/CategoryShop/CategoryShop.vue'),
          },
        },
        {
          path: 'material',
          components: {
            default: import ('@/views/Gre/Gre.vue'),
            gre: () => import ('@/modules/CategoryMaterial/CategoryMaterial.vue'),
          },
        },
        {
          path: 'article',
          components: {
            default: import ('@/views/Gre/Gre.vue'),
            practical: () => import ('@/modules/CategoryArticles/CategoryArticles.vue'),
          },
        },
      ],
    },

    {
      path: '/gmat',
      name: 'gmat',
      component: () => import ('@/modules/CategoryWrap/CategoryWrap.vue'),
      redirect: '/gmat/index',
      mate: { keepAlive: false },
      children: [
        {
          path: 'index',
          mate: { keepAlive: false },
          components: {
            default: import ('@/views/Gmat/Gmat.vue'),
            gmat: () => import ('@/modules/CategoryIndex/CategoryIndex.vue'),
          },
        },
        {
          path: 'series',
          // props: { gmat: true },
          name: 'gmatSeries',
          components: {
            default: import ('@/modules/CategorySeries/CategorySeries.vue'),
            gmat: () => import ('@/modules/CategorySeries/CategorySeries.vue'),
          },
        },
        {
          path: 'community',
          name: 'gmatCommunity',
          components: {
            default: import ('@/views/Gmat/Gmat.vue'),
            gmat: () => import ('@/modules/CategoryCommunity/CategoryCommunity.vue'),
          },
        },
        {
          path: 'activity',
          components: {
            default: import ('@/views/Gmat/Gmat.vue'),
            gmat: () => import ('@/modules/CategoryActivity/CategoryActivity.vue'),
          },
        },
        {
          path: 'shop',
          components: {
            default: import ('@/views/Gmat/Gmat.vue'),
            gmat: () => import ('@/modules/CategoryShop/CategoryShop.vue'),
          },
        },
        {
          path: 'material',
          components: {
            default: import ('@/views/Gmat/Gmat.vue'),
            gmat: () => import ('@/modules/CategoryMaterial/CategoryMaterial.vue'),
          },
        },
        {
          path: 'article',
          name: 'gmatArticle',
          components: {
            default: import ('@/views/Gmat/Gmat.vue'),
            gmat: () => import ('@/modules/CategoryArticles/CategoryArticles.vue'),
          },
        },
      ],
    },
    {
      path: '/pte',
      name: 'pte',
      component: () =>
          import ('@/modules/CategoryWrap/CategoryWrap.vue'),
      redirect: '/pte/index',
      children: [
        {
          path: 'index',
          components: {
            default: import ('@/views/Pte/Pte.vue'),
            pte: () => import ('@/modules/CategoryIndex/CategoryIndex.vue'),
          },
        },
        {
          path: 'series',
          // props: { pte: true },
          name: 'pteSeries',
          components: {
            default: import ('@/modules/CategorySeries/CategorySeries.vue'),
            pte: () => import ('@/modules/CategorySeries/CategorySeries.vue'),
          },
        },
        {
          path: 'community',
          name: 'pteCommunity',
          components: {
            default: import ('@/views/Pte/Pte.vue'),
            pte: () => import ('@/modules/CategoryCommunity/CategoryCommunity.vue'),
          },
        },
        {
          path: 'activity',
          components: {
            default: import ('@/views/Pte/Pte.vue'),
            pte: () => import ('@/modules/CategoryActivity/CategoryActivity.vue'),
          },
        },
        {
          path: 'shop',
          components: {
            default: import ('@/views/Pte/Pte.vue'),
            pte: () => import ('@/modules/CategoryShop/CategoryShop.vue'),
          },
        },
        {
          path: 'material',
          components: {
            default: import ('@/views/Pte/Pte.vue'),
            gmat: () => import ('@/modules/CategoryMaterial/CategoryMaterial.vue'),
          },
        },
        {
          path: 'article',
          components: {
            default: import ('@/views/Pte/Pte.vue'),
            gmat: () => import ('@/modules/CategoryArticles/CategoryArticles.vue'),
          },
        },
      ],
    },

    {
      path: '/alevel/index',
      name: 'alevel',
      component: () =>
          import ('@/views/ALevel/ALevel.vue'),
    },
    {
      path: '/duolingo',
      name: 'duolingo',
      component: () =>
          import ('@/modules/CategoryWrap/CategoryWrap.vue'),
      redirect: '/duolingo/index',
      children: [
        {
          path: 'index',
          components: {
            default: import ('@/views/Duolingo/Duolingo.vue'),
            duolingo: () => import ('@/modules/CategoryIndex/CategoryIndex.vue'),
          },
        },
        {
          path: 'series',
          // props: { duolingo: true },
          name: 'duolingoSeries',
          components: {
            default: import ('@/modules/CategorySeries/CategorySeries.vue'),
            duolingo: () => import ('@/modules/CategorySeries/CategorySeries.vue'),
          },
        },
        {
          path: 'community',
          name: 'duolingoCommunity',
          components: {
            default: import ('@/views/Duolingo/Duolingo.vue'),
            duolingo: () => import ('@/modules/CategoryCommunity/CategoryCommunity.vue'),
          },
        },
        {
          path: 'activity',
          components: {
            default: import ('@/views/Duolingo/Duolingo.vue'),
            duolingo: () => import ('@/modules/CategoryActivity/CategoryActivity.vue'),
          },
        },
        {
          path: 'shop',
          components: {
            default: import ('@/views/Duolingo/Duolingo.vue'),
            duolingo: () => import ('@/modules/CategoryShop/CategoryShop.vue'),
          },
        },
      ],
    },
    {
      path: '/eap',
      name: 'eap',
      component: () => import ('@/views/Eap/Eap.vue'),
    },

    {
      path: '/practical',
      name: 'practical',
      component: () => import ('@/modules/CategoryWrap/CategoryWrap.vue'),
      redirect: '/practical/index',
      children: [
        {
          path: 'index',
          components: {
            default: import ('@/views/Practical/Practical.vue'),
            practical: () => import ('@/modules/CategoryIndex/CategoryIndex.vue'),
          },
        },
        {
          path: 'series',
          // props: { practical: true },
          name: 'practicalSeries',
          components: {
            default: import ('@/modules/CategoryShop/CategoryShop.vue'),
            practical: () => import ('@/modules/CategoryShop/CategoryShop.vue'),
          },
        },
        {
          path: 'community',
          name: 'practicalCommunity',
          components: {
            default: import ('@/views/Practical/Practical.vue'),
            practical: () => import ('@/modules/CategoryCommunity/CategoryCommunity.vue'),
          },
        },
        {
          path: 'activity',
          components: {
            default: import ('@/views/Practical/Practical.vue'),
            practical: () => import ('@/modules/CategoryActivity/CategoryActivity.vue'),
          },
        },
        {
          path: 'shop',
          components: {
            default: import ('@/views/Practical/Practical.vue'),
            practical: () => import ('@/modules/CategoryShop/CategoryShop.vue'),
          },
        },
        {
          path: 'material',
          components: {
            default: import ('@/views/Practical/Practical.vue'),
            practical: () => import ('@/modules/CategoryMaterial/CategoryMaterial.vue'),
          },
        },
        {
          path: 'article',
          components: {
            default: import ('@/views/Practical/Practical.vue'),
            practical: () => import ('@/modules/CategoryArticles/CategoryArticles.vue'),
          },
        },
      ],
    },
    {
      path: '/japanese',
      name: 'japanese',
      component: () => import ('@/modules/CategoryWrap/CategoryWrap.vue'),
      redirect: '/japanese/index',
      children: [
        {
          path: 'index',
          components: {
            default: import ('@/views/Japanese/Japanese.vue'),
            japanese: () => import ('@/modules/CategoryIndex/CategoryIndex.vue'),
          },
        },
        {
          path: 'series',
          // props: { japanese: true },
          name: 'japaneseSeries',
          components: {
            default: import ('@/modules/CategorySeries/CategorySeries.vue'),
            japanese: () => import ('@/modules/CategorySeries/CategorySeries.vue'),
          },
        },
        {
          path: 'material',
          components: {
            default: import ('@/views/Japanese/Japanese.vue'),
            japanese: () => import ('@/modules/CategoryMaterial/CategoryMaterial.vue'),
          },
        },
        {
          path: 'community',
          name: 'practicalCommunity',
          components: {
            default: import ('@/views/Japanese/Japanese.vue'),
            japanese: () => import ('@/modules/CategoryCommunity/CategoryCommunity.vue'),
          },
        },
        {
          path: 'activity',
          components: {
            default: import ('@/views/Japanese/Japanese.vue'),
            japanese: () => import ('@/modules/CategoryActivity/CategoryActivity.vue'),
          },
        },
        {
          path: 'shop',
          components: {
            default: import ('@/views/Japanese/Japanese.vue'),
            japanese: () => import ('@/modules/CategoryShop/CategoryShop.vue'),
          },
        },
        {
          path: 'material',
          components: {
            default: import ('@/views/Japanese/Japanese.vue'),
            japanese: () => import ('@/modules/CategoryMaterial/CategoryMaterial.vue'),
          },
        },
        {
          path: 'article',
          components: {
            default: import ('@/views/Japanese/Japanese.vue'),
            japanese: () => import ('@/modules/CategoryArticles/CategoryArticles.vue'),
          },
        }
      ],
    },
    // 模考中心
    {
      path: '/mockCenter',
      name: 'mockCenter',
      component: () => import ('@/views/MockCenter/mockCenter.vue'),
    },
    {
      path: '/abroad',
      name: 'abroad',
      component: () => import ('@/views/Abroad/Abroad.vue'),
    },

    {
      path: '/tutoring',
      name: 'overseasTutoring',
      component: () => import ('@/views/OverseasTutoring/OverseasTutoring.vue'),
    },
    {
      path: 'https://exam.papaen.com/',
      name: 'mock',
    },

    {
      path: '/cart',
      name: 'cart',
      component: () => import ('@/views/Cart/Cart.vue'),
      meta: { noFooter: true, requireAuth: true },
    },

    {
      path: '/confirmCart',
      name: 'confirmCart',
      meta: { requireAuth: true, noFooter: true },
      component: () => import ('@/views/Cart/ConfirmCart/ConfirmCart.vue'),
    },
    {
      path: '/trainingSpeaking',
      name: 'trainingSpeaking',
      meta: {
        noFooter: true,
        requireAuth: true,
      },
      component: () => import ('@/views/TrainingSpeaking/TrainingSpeaking.vue'),
      children: [
        {
          path: 'main',
          name: 'trainingSpeakingMain',
          meta: {
            requireAuth: true,
            noFooter: true,
          },
          components: {
            training: () => import ('@/views/TrainingSpeaking/NewTrainingSpeakingList/NewTrainingSpeakingList.vue'),
          },
        },
        {
          path: 'feedback/:id/:category',
          name: 'trainingSpeakingFeedback',
          props: { training: true },
          meta: {
            requireAuth: true,
            noFooter: true,
          },
          components: {
            training: () => import ('@/views/TrainingSpeaking/TrainingFeedback/TrainingFeedback.vue'),
          },
        },
      ],
    },
    {
      path: '/course/:spuId/:skuId',
      name: 'Course',
      props: true,
      component: () => import ('@/views/Course/Course.vue')
    },
    // user
    {
      path: '/user',
      name: 'user',
      redirect: '/user/course',
      meta: {
        noFooter: true,
        requireAuth: true,
      },
      component: () => import ('@/views/User/User.vue'),
      children: [
        {
          path: 'schedule',
          meta: {
            requireAuth: true
          },
          components: {
            default: import ('@/views/User/User.vue'),
            user: () => import ('@/views/User/MySchedule/MySchedule.vue'),
          },
        },
        {
          path: 'course',
          name: 'userCourse',
          meta: {
            requireAuth: true
          },
          components: {
            default: import ('@/views/User/User.vue'),
            user: () => import ('@/views/User/MyCourse/MyCourse.vue'),
          },
        },
        {
          path: 'course/expired',
          meta: {
            requireAuth: true
          },
          components: {
            default: import ('@/views/User/User.vue'),
            user: () => import ('@/views/User/MyCourse/Expired/Expired.vue'),
          },
        },
        {
          path: 'activity',
          meta: {
            requireAuth: true
          },
          components: {
            default: import ('@/views/User/User.vue'),
            user: () => import ('@/views/User/MyActivity/MyActivity.vue'),
          },
        },
        {
          path: 'trainingSpeaking',
          meta: {
            requireAuth: true,
            noFooter: true,
          },
          components: {
            default: import ('@/views/User/User.vue'),
            user: () => import ('@/views/User/TrainingSpeaking/TrainingSpeaking.vue'),
          },
        },
        {
          path: 'coupon',
          meta: {
            requireAuth: true
          },
          components: {
            default: import ('@/views/User/User.vue'),
            user: () => import ('@/views/User/Coupon/Coupon.vue'),
          },
        },
        {
          path: 'orders',
          meta: {
            requireAuth: true
          },
          redirect: 'orders/list',
          components: {
            default: import ('@/views/User/User.vue'),
            user: () => import ('@/views/User/Order/Order.vue'),
          },
          children: [
            {
              path: 'list',
              meta: {
                noFooter: true
              },
              components: {
                default: import ('@/views/User/Order/List/List.vue'),
                order: () => import ('@/views/User/Order/List/List.vue'),
              },
            },
            {
              path: 'detail',
              components: {
                default: import ('@/views/User/Order/List/List.vue'),
                order: () => import ('@/views/User/Order/Detail/Detail.vue'),
              },
            },
            {
              path: 'pay',
              components: {
                default: import ('@/views/User/Order/List/List.vue'),
                order: () => import ('@/views/User/Order/Pay/Pay.vue'),
              },
            },
          ],
        },
        {
          path: 'account',
          meta: {
            requireAuth: true
          },
          components: {
            default: import ('@/views/User/User.vue'),
            user: () => import ('@/views/User/Account/Account.vue'),
          },
        },
        {
          path: 'courseDetail/:id',
          name: 'userCourseDetail',
          meta: { keepAlive: false },
          components: {
            user: () => import ('@/views/CourseDetail/CourseDetail.vue'),
          },
        },
        {
          path: 'classCourseDetail/:planId/:courseId',
          name: 'userClassCourseDetail',
          props: { user: true },
          meta: { keepAlive: false },
          components: {
            user: () => import ('@/views/ClassCourseDetail/CourseDetail.vue'),
          },
        },
        {
          path: 'pastCourseDetail/:courseId/:planId',
          name: 'userPastCourseDetail',
          props: { user: true },
          meta: { keepAlive: false },
          components: {
            user: () => import ('@/views/PastCourseDetail/PastCourseDetail.vue'),
          },
        },

      ],
    },


    // 课程详情
    {
      path: '/courseDetail/:id',
      name: 'courseDetail',
      meta: { keepAlive: false },
      component: () => import ('@/views/CourseDetail/CourseDetail.vue'),
    },

    {
      path: '/activityDetail/:id',
      name: 'activityDetail',
      meta: { keepAlive: false },
      component: () => import ('@/views/ActivityDetail/ActivityDetail.vue'),
    },

    // 往期视频详情

    {
      path: '/pastCourseDetail/:courseId',
      name: 'pastCourseDetail',
      props: true,
      meta: { keepAlive: false },
      component: () => import ('@/views/PastCourseDetail/PastCourseDetail.vue'),
    },

    // 直播课详情

    {
      path: '/liveDetail',
      name: 'liveDetail',
      component: () => import ('@/views/User/LiveDetail/LiveDetail.vue'),
      children: [
        {
          path: 'private/:id',
          meta: { keepAlive: false, noFooter: true },
          components: {
            default: import ('@/views/User/LiveDetail/LiveDetail.vue'),
            live: () => import ('@/views/User/LiveDetail/LivePrivateDetail/LivePrivateDetail.vue'),
          },
        },
        {
          path: 'class/:id',
          meta: { keepAlive: false, noFooter: true },
          components: {
            default: import ('@/views/User/LiveDetail/LiveDetail.vue'),
            live: () => import ('@/views/User/LiveDetail/LiveClassDetail/LiveClassDetail.vue'),
          },
        },
      ],
    },

    //   课程资料
    {
      path: '/material/:id',
      name: 'courseMaterial',
      meta: { keepAlive: false },
      component: () => import ('@/views/CourseMaterial/CourseMaterial.vue'),
    },

    {
      path: '/live/:id',
      name: 'live',
      meta: { keepAlive: false, noFooter: true },
      component: () => import ('@/views/Live/Live.vue'),
    },
    {
      path: '/private/live/:id',
      name: 'privateLive',
      meta: { keepAlive: false, noFooter: true },
      component: () => import ('@/views/Live/Live.vue'),
    },

    {
      path: '/training/live/:id',
      name: 'trainingLive',
      meta: { keepAlive: false, noFooter: true },
      component: () => import ('@/views/Live/Live.vue'),
    },

    {
      path: '/course/private/replay/:id',
      name: 'privateReplay',
      meta: { keepAlive: false, noFooter: true },
      component: () => import ('@/views/User/courseReplay/courseReplay.vue'),
    },
    {
      path: '/course/training/replay/:id',
      name: 'trainingReplay',
      meta: { keepAlive: false, noFooter: true },
      component: () => import ('@/views/User/courseReplay/courseReplay.vue'),
    },

    {
      path: '/course/replay/:id',
      name: 'courseReplay',
      meta: { keepAlive: false, noFooter: true },
      component: () => import ('@/views/User/courseReplay/courseReplay.vue'),
    },

    // down
    {
      path: '/download',
      name: 'download',
      component: () => import ('@/views/DownApp/DownApp.vue'),
    },

    //help
    {
      path: '/help',
      name: 'help',
      component: () => import ('@/views/Help/Help.vue'),
    },

    //contactUs
    {
      path: '/contactUs',
      name: 'contactUs',
      component: () => import ('@/views/ContactUs/ContactUs.vue'),
    },

    //joinUs
    {
      path: '/joinUs',
      name: 'joinUs',
      component: () => import ('@/views/JoinUs/JoinUs.vue'),
    },

    //aboutUs
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import ('@/views/AboutUs/AboutUs.vue'),
    },

    //ExamPosition
    {
      path: '/examPosition',
      name: 'examPosition',
      component: () => import ('@/views/ExamPosition/ExamPosition.vue'),
    },

    // 服务协议
    {
      path: '/terms',
      name: 'terms',
      component: () => import ('@/views/Terms/Terms.vue'),
    },

    // 用户协议
    {
      path: '/agreement',
      name: 'agreement',
      meta: { noFooter: true },
      component: () => import ('@/views/Agreement/Agreement.vue'),
    },

    // 邀请页面
    {
      path: '/invite',
      name: 'invite',
      meta: { noFooter: true },
      component: () => import ('@/views/linkPage/invite.vue'),
    },
    {
      path: '/inviteSimple',
      name: 'inviteSimple',
      meta: { noFooter: true },
      component: () => import ('@/views/linkPage/inviteSimple.vue'),
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import ('@/views/Search/Search.vue')
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})
