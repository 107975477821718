import moment from 'moment'
import { canEnterLiveRoom, joinRoom } from '@/api/live/live'
import { COURSE_MODEL } from '@/enums/API'

function registerTimer ( intDiff, fn ) {
  var timer = window.setInterval(function () {
    var minute = 0,
        second = 0 //时间默认值
    if (intDiff > 0) {
      minute = Math.floor(intDiff / 60)
      second = Math.floor(intDiff) - minute * 60
    }
    if (minute <= 9) minute = '0' + minute
    if (second <= 9) second = '0' + second
    var timerText = minute + ':' + second

    if (intDiff == 0) {
      clearInterval(timer)
      if (fn) fn()
    }
    intDiff--
  }, 1000)
}

function formatDate ( msd ) {
  var time = msd
  if (null != time && '' != time) {
    if (time > 60 && time < 60 * 60) {
      time =
          parseInt(time / 60.0) +
          ' ' +
          parseInt((parseFloat(time / 60.0) - parseInt(time / 60.0)) * 60) +
          ' '
    } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
      time =
          parseInt(time / 3600.0) +
          ' ' +
          parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) +
          ' ' +
          parseInt(
              (parseFloat(
                      (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
                  ) -
                  parseInt(
                      (parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60
                  )) *
              60
          ) +
          ''
    } else if (time >= 60 * 60 * 24) {
      time =
          parseInt(time / 3600.0 / 24) +
          ' ' +
          parseInt(
              (parseFloat(time / 3600.0 / 24) - parseInt(time / 3600.0 / 24)) * 24
          ) +
          ' ' +
          parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) +
          ' '
    } else {
      time = parseInt(time) + ' '
    }
  }
  return time
}

function switchCategory ( path ) {
  console.log(path)
  console.log('=>(utils.js:69) ', );
  let category = path.split('/')[ 1 ]
  let category_id = ''
  switch (category) {
    case 'ielts':
      category_id = 1
      break
    case 'toefl':
      category_id = 2
      break
    case 'gre':
      category_id = 3
      break
    case 'gmat':
      category_id = 4
      break
    case 'practical':
      category_id = 5
      break
    case 'pte':
      category_id = 6
      break
    case 'alevel':
      category_id = 10
      break
    case 'japanese':
      category_id = 11
      break
    default:
      category_id = 1
      break
  }
  return category_id
}

function checkDevice () {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(
      window.navigator.userAgent
  ) ?
      'h5' :
      'pc'
}

function changeDevice ( params ) {
  let h5Host = process.env.VUE_APP_H5_DOMAIN
  if (checkDevice() === 'h5') {
    let targetPath = findPath(params)
    window.location.href = `${h5Host}${targetPath}`
  }
}

function findPath ( path ) {
  console.log(path)
  const courseReg = /course\/\d*\/\d*/
  const materialReg = /material\/\d*/
  const abroadReg = /abroad/
  const pastCourseReg = /pastCourseDetail\/(\d*)/
  const downloadReg = /download/

  const communityReg = /community\/(\d*)/
  const activityDetail = /activityDetail\/(\d*)/
  const categoryListReg = /(ielts|toefl|gre|gmat|pte|japanese)/
  if (
      courseReg.test(path) ||
      materialReg.test(path) ||
      abroadReg.test(path)
  ) {
    return path
  } else if (communityReg.test(path)) {
    const id = path.match(communityReg)[ 1 ]
    return `/activityDetail/${id}`
  } else if (activityDetail.test(path)) {
    const id = path.match(activityDetail)[ 1 ]
    return `/community/${id}`
  } else if (pastCourseReg.test(path)) {
    const id = path.match(pastCourseReg)[ 1 ]
    return `/publicCourse/${id}`
  } else if (categoryListReg.test(path)) {
    const category = path.match(categoryListReg)[ 1 ]
    return `/category/${category}`
  } else if (downloadReg.test(path)) {
    return '/download/ielts'
  } else {
    return '/'
  }
}

function jumpPage ( url ) {
  window.open(url)
}

function resolveAddress ( addressObj ) {
  if (parseInt(addressObj.method) === 1) {
    return addressObj.link_url
  } else if (parseInt(addressObj.method) === 2) {
    if (addressObj.link_url.indexOf('cloud_reader') > -1) {
      return '/download'
    }
    let addressArr = addressObj.link_url.split('/')
    let type = addressArr[ 0 ]
    let id = type === 'spu' ? `${addressArr[ 1 ]}/${addressArr[ 2 ]}` : addressArr[ 1 ]
    switch (type) {
      case 'spu':
        return `/course/${id}`
      case 'materials':
        return `material/${id}`
      default:
        return '/download'
    }
  } else if (parseInt(addressObj.method) === 4) {
    return 'qrCode'
  } else {
    return addressObj.link_url
  }
}

function findUser ( user, list ) {
  let userIndex = ''
  list.forEach(( ele, index ) => {
    if (ele.userID === user.userID) {
      userIndex = index
    }
  })
  return userIndex
}

function formatMoment ( time, type = 'YYYY-MM-DD HH:mm:ss', timezone ) {
  if (time === 0 || time === null) {
    return '-'
  }
  if (timezone) {
    let tz = new Date().getTimezoneOffset() / 60
    return moment(time * 1000)
        .utcOffset(8)
        .utcOffset(-tz)
        .format(type)
  } else {
    return moment(time * 1000)
        .utcOffset(8)
        .format(type)
  }
}

function liveFormatMoment ( starTime, endTime ) {
  if (starTime === 0 || starTime === null || endTime === 0 || endTime === null) {
    return '-'
  } else {
    return moment(starTime * 1000).format('YYYY年MM月DD日 HH:mm') + '-' + moment(endTime * 1000).format('HH:mm')
  }
}

function recordedDuration ( timeLength ) {
  if (timeLength === 0 || timeLength === null) {
    return '-'
  }
  if (timeLength > 60) {
    return parseInt(timeLength / 60) + '时' + timeLength % 60 + '分钟'
  }
  if (timeLength < 60 && timeLength !== 0) {
    return 0 + '时' + 0 + '分钟' + timeLength + '秒'
  }
}

function translateTag ( id ) {
  let types = this.$store.state.common.categoryTypes
  let tag = types.find(( item ) => {
    return item.id === id
  })
  return tag || undefined
}

function openPage ( url ) {
  window.open(url, '_blank')
}

function getLiveRoomStatus ( id, liveMode ) {
  return new Promise(( resolve, reject ) => {
    canEnterLiveRoom(
        id,
        liveMode,
        ( res ) => {
          resolve(res)
        },
        ( err ) => {
          reject(err)
        }
    )
  })
}

function openLiveRoom ( url, liveMode, id, route, context ) {
  console.log(route.path.indexOf('trainingSpeaking') )
  getLiveRoomStatus(id, liveMode)
      .then(( res ) => {
        if(route.path.indexOf('trainingSpeaking') > -1) {
          if(res.data.live_room_id) {
            context.$alert('教室将在新窗口打开', '提示', {
              confirmButtonText: '确定',
            }).then(() => {
                joinRoom(id, {type: 1})
                openPage(url)
            })
          }else {
            context.$message.warning('房间号不存在')
          }
        }else {
          context.$alert('教室将在新窗口打开', '提示', {
            confirmButtonText: '确定',
          }).then(() => {
              openPage(url)
          })
        }

      })
      .catch(( err ) => {
        console.log(err)
      })
}

function getBrowserInfo () {
  var agent = navigator.userAgent.toLowerCase()
  var regStr_ie = /msie [\d.]+;/gi
  var regStr_ff = /firefox\/[\d.]+/gi
  var regStr_chrome = /chrome\/[\d.]+/gi
  var regStr_saf = /safari\/[\d.]+/gi
  var isIE = agent.indexOf('compatible') > -1 && agent.indexOf('msie' > -1) //判断是否IE<11浏览器
  var isEdge = agent.indexOf('edge') > -1 && !isIE //判断是否IE的Edge浏览器
  var isIE11 = agent.indexOf('trident') > -1 && agent.indexOf('rv:11.0') > -1
  if (isIE) {
    var reIE = new RegExp('msie (\\d+\\.\\d+);')
    reIE.test(agent)
    var fIEVersion = parseFloat(RegExp[ '$1' ])
    if (fIEVersion == 7) {
      return 'IE/7'
    } else if (fIEVersion == 8) {
      return 'IE/8'
    } else if (fIEVersion == 9) {
      return 'IE/9'
    } else if (fIEVersion == 10) {
      return 'IE/10'
    }
  } //isIE end
  if (isIE11) {
    return 'IE/11'
  }
  //firefox
  if (agent.indexOf('firefox') > 0) {
    return agent.match(regStr_ff)
  }
  //Safari
  if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
    return agent.match(regStr_saf)
  }
  //Chrome
  if (agent.indexOf('chrome') > 0) {
    return agent.match(regStr_chrome)
  }
}

function getBrower () {
  let browser = getBrowserInfo()
  let b_name = (browser + '').replace(/[0-9./]/gi, '')
  let b_version = parseInt((browser + '').replace(/[^0-9.]/gi, ''))
  return {
    name: b_name,
    version: b_version,
  }
}

function subSet ( arr1, arr2 ) {
  let set2 = new Set(arr2)
  let subset = []
  arr1.forEach(function ( val, index ) {
    if (!set2.has(val)) {
      subset.push(val)
    }
  })
  return subset
}

function papaFundeBug ( name, message, option ) {
  fundebug.notify(name, message, option)
}

function getWeek ( date ) {
  var week
  if (date.getDay() == 0) week = '周日'
  if (date.getDay() == 1) week = '周一'
  if (date.getDay() == 2) week = '周二'
  if (date.getDay() == 3) week = '周三'
  if (date.getDay() == 4) week = '周四'
  if (date.getDay() == 5) week = '周五'
  if (date.getDay() == 6) week = '周六'
  return week
}

// 将秒转换成 分:秒 （也可转为时：分：秒）
function getTime(time) {
  // 转换为式分秒
  // let h = parseInt(time / 60 / 60 % 24)
  // h = h < 10 ? '0' + h : h
  let m = parseInt(time / 60 % 60)
   m = m < 10 ? '0' + m : m
  let s = parseInt(time % 60)
   s = s < 10 ? '0' + s : s
  // 作为返回值返回
  // return [h, m, s]
  return [m, s]
}


function getRenderTime(){
  window.addEventListener('load', (event) => {
    // 获取当前时间戳
    const now = performance.now();

    // 获取页面加载完成的时间
    const loadTime = now;

    // 记录页面加载完成的时间戳
    console.log(`Page load time is ${loadTime.toFixed(2)} milliseconds.`);

    // 获取性能条目
    const performanceEntries = performance.getEntriesByType('navigation');
    if (performanceEntries.length > 0) {
      const pageLoadEntry = performanceEntries[0];

      // DNS 查询时间
      const dnsTime = pageLoadEntry.domainLookupEnd - pageLoadEntry.domainLookupStart;

      // TCP 连接时间
      const tcpTime = pageLoadEntry.connectEnd - pageLoadEntry.connectStart;

      // 服务器响应时间
      const responseTime = pageLoadEntry.responseEnd - pageLoadEntry.requestStart;

      // DOM 解析时间
      const domContentLoadedTime = pageLoadEntry.domContentLoadedEventEnd - pageLoadEntry.startTime;

      // 页面完全加载时间
      const totalLoadTime = pageLoadEntry.loadEventEnd - pageLoadEntry.startTime;

      console.log(`DNS query time: ${dnsTime.toFixed(2)} milliseconds.`);
      console.log(`TCP connection time: ${tcpTime.toFixed(2)} milliseconds.`);
      console.log(`Server response time: ${responseTime.toFixed(2)} milliseconds.`);
      console.log(`DOM content loaded time: ${domContentLoadedTime.toFixed(2)} milliseconds.`);
      console.log(`Total page load time: ${totalLoadTime.toFixed(2)} milliseconds.`);
    }
  });
}

export {
  registerTimer,
  formatDate,
  switchCategory,
  checkDevice,
  changeDevice,
  jumpPage,
  resolveAddress,
  findUser,
  formatMoment,
  translateTag,
  openLiveRoom,
  openPage,
  getLiveRoomStatus,
  getBrower,
  getBrowserInfo,
  subSet,
  papaFundeBug,
  getWeek,
  liveFormatMoment,
  recordedDuration,
  getTime,
  getRenderTime
}
