export default {
  COURSE_MODEL: {
    'LIVE': 1,   // 直播
    'RECORD': 2, // 录播
    'VIDEO': 3,   // 视频
    'TRIAL': 4   // 抢先听
  },

  COURSE_TYPE: {
    'COMMUNITY': 1, //公益课
    'LIVE_FEE': 2,   //收费课
  },
  CLASS_TYPE: {
    'PRIVATE': 1,
    'CLASS': 2
  },
  LIVE_MODE: {
    'PRIVATE': 1,
    'CLASS': 2,
    'TRAINING': 3
  },
}
